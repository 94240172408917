<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('languages.edit')">
        <actions
          slot="actions"
          crud-links="languages"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :language="language"
          :loading="loading"
          @submit="submit"
        ></data-form>
      </va-card>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { loadLanguageAsync } from '@/i18n'

const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'languages-edit',
  components: {
    Actions,
    DataForm,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      language: {
        translation: {},
      },
    }
  },
  computed: {
    ...mapGetters(['currentLocale']),
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `languages/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const languageId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(languageId))
      } catch (err) {
        // console.log('Error fetching language data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.language = { ...u.data.data, translation: JSON.parse(u.data.data.translation) }
    },
    async submit (language) {
      this.loading = true
      try {
        await this.$http.put(this.routeBuilder(language.id), language)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      if (language.alpha_code === this.currentLocale) {
        await loadLanguageAsync(language.alpha_code, true)
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
